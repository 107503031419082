@import './variables.scss';
.footer {
	height: 10vh;
	min-height: 88px;
	width: 100vw;
	position: fixed;
	bottom: 0;
	filter: drop-shadow(0px -2px 5px rgba(162, 162, 162, 0.1));
	button {
		border: 0px solid transparent;
		width: 25vw;
		height: 10vh;
		min-height: 88px;
	}
	.ant-image-img {
		width: 10vw;
	}
}
@media (max-height: 520px) {
	.footer {
		display: none;
	}
}
