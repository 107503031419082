@import './variables.scss';
.product-substitution {
	height: 84vh;
	.col-container {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		max-height: 100%;
		min-height: 97%;
		max-width: 92%;
	}
	.line-item {
		background: #f5f5f2;
		border: 0.25px solid #d8d8d8;
		box-sizing: border-box;
		border-radius: 8px;
		margin-bottom: 26px;
		margin-top: 16px;
		.line-item-name {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 0.875rem;
			line-height: 16px;
			color: #212121;
		}
		.line-item-info {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 16px;
			color: #212121;
		}
		.subs-link {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 16px;
			text-decoration-line: underline;
			color: #000099;
		}
		.line-item-img {
			width: 80%;
		}
	}
	.sub-title {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 134%;
		color: #212121;
		margin-bottom: 0;
	}
	.sub-title-description {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 450;
		font-size: 0.75rem;
		line-height: 136%;
		color: #212121;
		margin-top: 0 !important;
		margin-bottom: 0;
	}
	.substitution-container {
		overflow-y: auto;
		flex: 1 1;
		padding-bottom: 16px;
		.subs-item {
			background: #f5f5f2;
			border: 0.25px solid #d8d8d8;
			box-sizing: border-box;
			border-radius: 8px;
			margin-top: 16px;
			.name {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 900;
				font-size: 0.875rem;
				line-height: 16px;
				color: #212121;
			}
			.code {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 0.875rem;
				line-height: 16px;
				color: #212121;
			}
		}
	}
	.ant-breadcrumb-link a {
		font-size: 0.8rem;
	}
}
.ps .ant-breadcrumb .ant-breadcrumb-separator,
.ps .ant-breadcrumb a {
	font-size: 0.875rem;
}
