@import './variables.scss';
.order-head {
	height: 74vh;
	.col-container {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		max-height: 100%;
		min-height: 97%;
		max-width: 92%;
		.old-iphone{
			display: block;
		}
		.order-details {
			min-height: 190px;
		}
		.main-title {
			min-height: 33px;
		}
		.search-container {
			min-height: 56px;
		}
		.filter-row {
			min-height: 47px;
		}
		.sub-title {
			min-height: 47px;
		}
		.icon-container {
			.ant-image-img {
				width: 1.125rem;
			}
			svg {
				transform: scale(1.4);
			}
			.anticon {
				position: relative;
				top: 2px;
				left: 9px;
			}
		}
	}
	.order-item {
		background-color: #f5f5f2;
		border-radius: 8px;
		margin-bottom: 16px;
		margin-top: 16px;
		.order-item-order-no {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 1.25rem;
			line-height: 121%;
			color: #212121;
			margin-bottom: 0;
		}
		.order-item-account-info {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 16px;
			color: #000099;
		}
		.updated-date {
			font-size: 0.75rem !important;
		}
		.order-item-order-detail {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 16px;
			color: #212121;
		}
		.space-maker {
			height: 12px;
		}
		.chavlon-icon {
			top: 33%;
			position: relative;
		}
		.ant-card-body {
			padding: 16px 24px;
		}
		.ant-progress-success-bg,
		.ant-progress-bg {
			background-color: #63666a;
			height: 16px !important;
			background-image: linear-gradient(
				270deg,
				#88888d -62.08%,
				rgba(136, 136, 141, 0) 150%
			);
			box-shadow: inset 1px -2px 1px rgba(213, 213, 213, 0.05),
				inset -1px 2px 1px rgba(80, 80, 80, 0.1),
				inset 1px 2px 1px rgba(212, 212, 212, 0.05),
				inset -1px -2px 1px rgba(80, 80, 80, 0.1);
			border-radius: 4px;
		}
		.ant-progress-inner {
			height: 16px;
			background-color: #d8d8d8;
			box-shadow: inset 0px 4px 4px rgba(69, 69, 69, 0.25);
			border-radius: 4px;
		}
		.order-status-icon {
			width: 5vw;
		}
	}
	.page-header-text {
		margin-top: 0px;
		margin-bottom: 0;
	}
	.search {
		margin-top: 16px;
		background-color: #f4f4f4;
		border-radius: 8px;
		border: 0px;
		height: 40px;
		input {
			background-color: #f4f4f4;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 1rem;
			color: #5f5f5f;
		}
		.ant-image-img {
			width: 8vw;
		}
	}

	.active-filter {
		background-color: #212121;
		color: #fff;
	}
	.inactive-filter {
		background-color: #f4f4f4;
		color: #63666a;
	}
	.filter-container {
		overflow-x: auto;
		overflow-y: hidden;
		height: 7vh;
		width: 100vw;
		display: flex;
		.filter {
			height: 40px;
			border-radius: 8px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 1rem;
			border: 0px solid transparent;
			margin-top: 8px;
			margin-right: 8px;
			.filter-button-icon {
				width: 20px;
				position: relative;
				left: -4px;
				top: 8px;
				z-index: 0;
			}
			span {
				position: relative;
				left: -7px;
				top: 6px;
				font-family: 'Circular Std';
				font-style: normal;
				font-weight: 450;
				font-size: 1rem;
				line-height: 1rem;
			}
			.ant-image {
				left: -7px;
				top: -4px;
				height: 40px !important;
				width: 34px;
				height: 100%;
				border-right: 1px solid rgba(0, 0, 0, 0.1);
			}
		}
		.filter:focus {
			border: 1px solid #d0d0d0;
		}
		.filter:active {
			background-color: #d8d8d8;
		}
		.Backordered-selected {
			background-color: #ca0d1b;
		}
		.Canceled-selected {
			background-color: #ca0d1b;
		}
		.Progress-selected {
			background-color: #ffa800;
		}
		.Shipped-selected {
			// background-color: #ffa800;
			background-color: #0b8314;
		}
		.Submitted-selected {
			background-color: #ffa800;
		}
		.Completed-selected {
			background-color: #0b8314;
		}
	}

	.filter-name-text {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: bold;
		font-size: 1rem;
		line-height: 149.5%;
		color: #212121;
		margin: 8px 0px 16px;
	}
	.li-container {
		overflow-y: auto;
		flex: 1 1;
		align-items: flex-start;
		.line-item {
			background: #f5f5f2;
			border: 0.25px solid #d8d8d8;
			box-sizing: border-box;
			border-radius: 8px;
			margin-bottom: 16px;
			.line-item-name {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 900;
				font-size: 0.875rem;
				line-height: 16px;
				color: #212121;
			}
			.line-item-info {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 0.875rem;
				line-height: 16px;
				color: #212121;
			}
			.subs-link {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 0.875rem;
				line-height: 16px;
				text-decoration-line: underline;
				color: #000099;
			}
			.line-item-img {
				width: 80%;
			}
		}
	}
}
