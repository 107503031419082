@import './variables.scss';
$primary_color: #ffffff;
$secondary_color: #c4c4c4;
$borderRadius: 16px;
$blackText: #1b1b1b;
$searchBackground: #f4f4f4;
$accountListItemBackground: #ebebe6;

.blue-text {
	color: #000099 !important;
}

.account__list__account__index {
	margin-bottom: 100px;
	position: relative;
	height: 100vh;
	background: $primary_color;
	border-radius: $borderRadius;
	overflow-y: hidden;
	.topbar {
		width: 100%;
		height: 7vh;
		background: #ffffff;
		min-height:48px;
	}
	.account__list__top__bar {
		width: 100%;
		height: 48px;
		left: 0px;
		margin-top: 1vh;
		display: flex;
		background: #fff;
		border: 1px solid #d8d8d8;
		padding: 0 20px;
		.call_icon {
			margin-left: auto;
		}
	}
	.buttons-tab {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-top: 1vh;
		padding: 0 16px;

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0px;
			width: 198px;
			height: 48px;
			left: 198px;
			border: 1px solid #ca0d1b;
			color: #ca0d1b;
			background: #fff;
			cursor: pointer;
		}

		.red {
			background: #ca0d1b;
			color: #fff;
			font-family: $CircularStdBlack;
		}
	}
}
.search-container {
	width: 100%;
	height: 40px;
	padding: 0 16px;
	margin-top: 1vh;
	.account__list__search {
		background: #f4f4f4;
		border-radius: 8px;
		height: 100%;

		.search-image {
			width: 8vw;
		}
	}
}

@mixin placeholder {
	::-webkit-input-placeholder {
		@content;
	}
	:-moz-placeholder {
		@content;
	}
	::-moz-placeholder {
		@content;
	}
	:-ms-input-placeholder {
		@content;
	}
}

@include placeholder {
	font-family: $CircularStdBlack;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5f5f5f !important;
}

.account__list__account__index {
	input {
		background: $searchBackground;
	}
}

.account__list__container {
	margin-top: 1.5vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: 62vh;
	overflow-y: auto;
	padding: 0 16px 30px;
	.loading-container {
		height: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	.ant-empty {
		width: 95%;
	}
}
a {
	width: 100%;
}
.account__list__item {
	position: relative;
	margin: 8px 0px;
	bottom: 0%;
	width: 100%;
	min-height: 144px;
	background: #f5f5f2;
	border-radius: 8px;
	display: flex;
	padding: 0 24px;
	.account__list__description {
		display: flex;
		flex-direction: column;
		// align-items: flex-start;
		position: absolute;
		width: 75%;
		top: 24px;
		.account__list__description__name {
			width: 269px;
			left: 24px;
			top: 24px;
			font-family: $self-serve-primary-font-family;
			font-weight: bold;
			font-size: 1.1rem;
			line-height: 121%;
			color: #1b1b1b;
		}
		.account__list__main__description {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0px;

			position: static;
			width: 100%;
			height: 16px;
			left: 0px;
			top: 32px;
			.account__list__main__description__text {
				position: static;
				width: 100%;
				height: 16px;
				left: 0px !important;
				top: 0px !important;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: $blackText;
			}
		}
	}

	.rightArrow-container {
		display: flex;
		align-items: center;
		margin-left: auto;
	}
}

path:hover {
	fill: #000000;
}
